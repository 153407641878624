<template>
    <v-container fill-height fluid class="black d-flex align-stretch justify-center">
        <v-row class=""  justify="center" no-gutters>
        <v-col cols="12" md="11" class="py-0 px-0" >
                <menus/>
            <v-row justify="center" class="my-0 px-0 py-0" >
                <v-col cols="12" md="5" class="px-0 py-0" ><!-- Contenido de la izquierda-->
                    <v-responsive  align="center" class="d-none d-md-flex">
                        <!--imagen si es escritorio-->
                        <v-img
                            width="100%"
                            class="d-none d-md-flex align-center justify-start"
                            :src="require('../assets/fiestasWeb.png')"
                        >  
                        </v-img>                        
                    </v-responsive>
                </v-col>
                <v-col cols="12" md="6"  class="text-center white--text py-0" align="start">
                    <h1 class="font-weight-black mb-5">
                        EVENTOS 
                    </h1>
                    <h4 class="text-left mb-10">
                        PARTICIPA Y GANA UNA ENTRADA PARA IR A UNO<v-spacer/> DE LOS SIGUIENTES EVENTOS:
                    </h4>
                    <v-spacer/>
                    <fiesta  
                        v-for="(item, i) in party"
                        :key="i"
                        :dia="item.event_day"
                        :mes="item.event_month"
                        :lugar="item.event_hour + ' ' + item.event_place"
                        :artista="item.event_name"
                        :id="item.id"
                        :provider_id="provider_id"
                    />
                    <!-- <fiesta dia="07" mes="NOV" artista="NICO COTTON" lugar="SÁBADO 8:00PM - FORO SOL, CIUDAD DE MEXICO" /> -->
                </v-col>        
            </v-row>
        </v-col>
        <v-col cols="12">
            <v-img
                
                width="100%"
                min-width="200"
                class="d-md-none align-center justify-start"
                :src="require('../assets/fiestasMobile.png')"
            >  
            </v-img>
        </v-col>
    </v-row>
    </v-container>

</template>
<script>

    import menus from '../components/menus.vue'
    import fiesta from '../components/fiesta.vue'
    import axios from "axios";
    export default {
        name: 'Fiestas',
        components: {
            menus,
            fiesta
        },
        data: () => ({
            party: []
        }),
        methods: {
            async iniciar() {

              await axios
                .post("events.php", {})
                .then(function (response) {
                  // console.log(response)
                  // console.log(response.data)

                  sessionStorage.array_data = JSON.stringify(response.data);
                })
                .catch((error) => {
                  // console.log(error)
                });

              this.party = JSON.parse(sessionStorage.array_data);
              // console.log(this.party)
            }
        },
        created(){
            this.iniciar()
        }
    }
</script>
<style>

</style>
