<template>
    <v-row class="d-flex justify-center no-gutters my-3 mx-0" no-gutters>
            <v-col  cols="3" md="2" class="d-flex align-center">
                <v-card color="rgba(255, 255, 255, 0.2)" aspect-ratio="1"  rounded="xl" dark>
                    <v-responsive :aspect-ratio="1" align="center" class="justify-center">
                        <v-card-title class="justify-center"><h3>{{this.dia}}</h3></v-card-title>
                        <v-card-subtitle class="">{{this.mes}}</v-card-subtitle>
                    </v-responsive>
                </v-card>
            </v-col>
            <v-col cols="7" md="9" class="">
                <v-card color="rgba(0, 0, 0, 0)"  elevation="0" dark>
                        <v-card-title class="titulo">{{this.artista}}</v-card-title>
                        <v-card-subtitle class=""><h5 align="left">{{this.lugar}}</h5></v-card-subtitle>
                </v-card>
            </v-col>
            <v-col  cols="1" class=" d-flex align-center">
                <!-- mobile -->
                <v-btn
                v-if="show_events != 'apagar'"
                icon
                class="d-flex d-md-none"
                color="becks"
                @click="sheet = !sheet;"
                small
                >
                    <v-icon large>mdi-chevron-right</v-icon>
                </v-btn>
                <!-- web desktop -->
                <v-btn
                v-if="show_events != 'apagar'"
                icon
                class="d-none d-md-flex"
                color="becks"
                x-large
                @click="cambiar2()"
                >
                    <v-icon x-large>mdi-chevron-right</v-icon>
                </v-btn>
                <!-- Modal para mobile -->
                <div
                v-else
                >
                <v-btn
                    
                    icon
                    class="d-md-none"
                    color="becks"
                    small
                    @click="dialog3 = !dialog3"
                >
                    <v-icon large>mdi-chevron-right</v-icon>
                </v-btn>
                <!-- modal para web -->
                <v-btn
                    
                    icon
                    class="d-none d-md-flex"
                    color="becks"
                    x-large
                    @click="dialog3 = !dialog3"
                >
                    <v-icon x-large>mdi-chevron-right</v-icon>
                </v-btn>
                </div>
            </v-col>
            <!-- aqui comienza el bottom-sheet para la confirmacion si es mobile-->
            <v-col cols="12">
                <v-bottom-sheet v-model="sheet" v-if="sheet">
                    <v-sheet
                        class="text-center rounded-t-xl"
                        width="100%"
                    >
                        <v-responsive  align="center" height="100%" class="align-center my-0 mx-0 becks rounded-t-xl" >
                            <v-card-title>
                                <v-spacer/>
                                <v-btn
                                        icon
                                        color="black"
                                        class="mr-n3 mb-n2 close_ticket"
                                        @click="sheet = !sheet"
                                    >
                                        <v-icon>mdi-close</v-icon>
                                    </v-btn>
                            </v-card-title>
                        <v-card-subtitle class="my-5">
                            <h2>¿ES ESTE EL EVENTO AL QUE QUIERES ASISTIR?</h2>
                        </v-card-subtitle>
                        <v-spacer/>
                        <v-card color="rgba(0, 0, 0, 0.25)" aspect-ratio="1" max-height="90" max-width="90" rounded="xl" >
                            <v-responsive :aspect-ratio="1" align="center">
                                <v-card-title class="justify-center"><h3>{{this.dia}}</h3></v-card-title>
                                <v-card-subtitle>{{this.mes}}</v-card-subtitle>
                            </v-responsive>
                        </v-card>
                        <v-card-title class="justify-center titulo">
                            {{this.artista}}
                        </v-card-title>
                        <v-card-subtitle>
                            {{this.lugar}}
                        </v-card-subtitle>
                        <v-btn
                            class="mx-auto my-5 black becks--text"
                            rounded
                            x-large
                            @click="cambiar()"
                        >
                            CONFIRMAR
                        </v-btn>
                    </v-responsive>
                    </v-sheet>
                </v-bottom-sheet>
                <v-dialog
                    v-if="dialog"
                    v-model="dialog"
                    width="100%"
                    height="100%"
                    fullscreen
                    persistent
                > <!-- aqui comienza el dialog para el QR si es mobile-->
                    <v-responsive  align="center"  class="align-center becks"  rounded="xl" style="overflow: hidden; " height="100%">
                        <v-card-title>
                                <v-spacer/>
                                <v-btn
                                        icon
                                        color="black"
                                        class="mr-n3 mb-n10 close_ticket"
                                        @click="dialog = !dialog; cambiarP('Participaciones')"
                                    >
                                        <v-icon>mdi-close</v-icon>
                                    </v-btn>
                            </v-card-title>
                        <div class="fllave d-flex align-center justify-center">
                            <h1>¡UNLOCKED!</h1>
                        </div>
                        <h5 class="mx-5" >
                            ÉSTA ES TU LLAVE PARA EL EVENTO:
                            <br>
                            ENTRADA V&Aacute;LIDA PARA 1 PERSONA.
                        </h5>
                        <v-card color="rgba(0, 0, 0, 0.25)" aspect-ratio="1" max-height="90" max-width="90" rounded="xl" class="d-flex align-center justify-center mt-1" >
                            <v-responsive :aspect-ratio="1" align="center">
                                <v-card-title class="justify-center black--text"><h3>{{this.dia}}</h3></v-card-title>
                                <v-card-subtitle>{{this.mes}}</v-card-subtitle>
                            </v-responsive>
                        </v-card>
                        <v-card-title class="justify-center titulo black--text">
                            {{this.artista}}
                        </v-card-title>
                        <v-card-subtitle class="black--text">
                            {{this.lugar}}
                        </v-card-subtitle>
                        <vue-qr text="asdasdisadiasd" backgroundColor="#00FF9D" logoBackgroundColor="#00FF9D" class="" margin="0" size="150"></vue-qr>
                        <br>
                        <v-btn
                            class="mx-auto black--text mb-5"
                            rounded
                            outlined
                            min-width="350px"
                            @click="atHome()"
                        >
                            GUARDAR
                        </v-btn>
                        <br>
                        <v-card color="rgba(0,0,0,0.3)" class="pt-10">
                            <h3>ESCUCHA NUESTRAS PLAYLIST</h3>
                            <v-btn
                                class="mx-auto black becks--text mb-5"
                                rounded
                                @click="cambiarP('Encuesta')"
                                min-width="350px"
                            >
                                ESCUCHAR
                            </v-btn>
                        </v-card>
                    </v-responsive>
                </v-dialog>
                <!-- Aqui comienza el dialog para confirmacion si es WEB -->
                <v-dialog
                    v-model="dialog2"
                    max-width="700"
                    
                    class="my-0 mx-0 py-0 px-0"
                >
                <v-responsive :aspect-ratio="20/9" align="center" height="100%" class="align-center becks rounded-xl " max-height="500">
                    <v-card-title>
                        <v-spacer/>
                        <v-btn
                                icon
                                color="black"
                                class="mr-n2 mb-n10 mt-1 close_ticket"
                                @click="dialog2 = !dialog2"
                            >
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                    </v-card-title>
                    <v-card 
                        class="becks"
                        max-width="500"
                        elevation="0"
                    >
                        <v-card-subtitle class="mb-5 mt-n1 black--text" >
                            <h3>
                            ¿ES ESTE ES EL EVENTO AL QUE QUIERES ASISTIR?
                            </h3>
                        </v-card-subtitle>
                        <v-spacer/>
                        <v-card color="rgba(0, 0, 0, 0.25)" aspect-ratio="1" max-height="90" max-width="90" rounded="xl" class="d-flex align-center justify-center" >
                            <v-responsive :aspect-ratio="1" align="center">
                                <v-card-title class="justify-center black--text"><h3>{{this.dia}}</h3></v-card-title>
                                <v-card-subtitle>{{this.mes}}</v-card-subtitle>
                            </v-responsive>
                        </v-card>
                        <v-card-title class="justify-center titulo black--text">
                            {{this.artista}}
                        </v-card-title>
                        <v-card-subtitle class="black--text">
                            {{this.lugar}}
                        </v-card-subtitle>
                        <v-btn
                            class="mx-auto my-5 black becks--text"
                            rounded
                            x-large
                            min-width="200"
                            @click="cambiar3()"
                        >
                            <h5> CONFIRMAR</h5>
                        </v-btn>
                    </v-card>
                </v-responsive>
                </v-dialog>
                <!-- Aqui comienza el dialog para boleto si es WEB -->
                <v-dialog
                    v-model="dialog3"
                    height="100%"
                    max-width="500"
                    persistent
                    fill-height
                    class="fill-height"
                >
                <v-card 
                    class="becks"
                    max-width="500"
                    rounded="xl"
                >
                    <v-responsive  align="center"  class="align-center becks"  rounded="xl" style="overflow: hidden; ">
                        <v-card-title>
                                <v-spacer/>
                                <v-btn
                                        icon
                                        color="black"
                                        class="mr-n3 mb-n10 close_ticket"
                                        @click="dialog3 = !dialog3; cambiarP('Participaciones')"
                                    >
                                        <v-icon>mdi-close</v-icon>
                                    </v-btn>
                            </v-card-title>
                        <div class="fllave d-flex align-center justify-center">
                            <h1>¡UNLOCKED!</h1>
                        </div>
                        <h5 class="mx-5" >
                            ÉSTA ES TU LLAVE PARA EL EVENTO:
                            <br>
                            ENTRADA V&Aacute;LIDA PARA 1 PERSONA.
                        </h5>
                        <v-card color="rgba(0, 0, 0, 0.25)" aspect-ratio="1" max-height="90" max-width="90" rounded="xl" class="d-flex align-center justify-center mt-1" >
                            <v-responsive :aspect-ratio="1" align="center">
                                <v-card-title class="justify-center black--text"><h3>{{this.dia}}</h3></v-card-title>
                                <v-card-subtitle>{{this.mes}}</v-card-subtitle>
                            </v-responsive>
                        </v-card>
                        <v-card-title class="justify-center titulo black--text">
                            {{this.artista}}
                        </v-card-title>
                        <v-card-subtitle class="black--text">
                            {{this.lugar}}
                        </v-card-subtitle>
                        <vue-qr text="Unlock Your Self" backgroundColor="#00FF9D" logoBackgroundColor="#00FF9D" class="" margin="0" size="150"></vue-qr>
                        <br>
                        <v-btn
                            class="mx-auto black--text mb-5"
                            rounded
                            outlined
                            min-width="350px"
                            @click="atHome()"
                        >
                            GUARDAR
                        </v-btn>
                        <br>
                        <v-card color="rgba(0,0,0,0.3)" class="pt-10">
                            <h3>ESCUCHA NUESTRAS PLAYLIST</h3>
                            <v-btn
                                class="mx-auto black becks--text mb-5"
                                rounded
                                @click="cambiarP('Encuesta')"
                                min-width="350px"
                            >
                                ESCUCHAR
                            </v-btn>
                        </v-card>
                    </v-responsive>
                </v-card>
                </v-dialog>
            </v-col>
        </v-row>
</template>
<script>
import axios from 'axios'
import VueQr from 'vue-qr'


export default {
    name: 'fiesta',
    components: {
        VueQr
    },
    data: () => ({
        dialog: false,
        dialog2: false,
        dialog3: false,
        sheet: false,
        show_events: "",
    }),
    props:[
        'dia',
        'mes',
        'artista',
        'lugar',
        'id',
        'provider_id',
        'show_event'
    ],
    methods: {
            cambiarP(lugar){
                axios.get('/').then(response => (
                    this.$router.push({name: lugar})
                ))
                .catch(
                    error => console.log('Hubo un error: ' + error)
                )
            },
            cambiar() {
                this.sheet = !this.sheet
                this.dialog = !this.dialog
                this.sendEvent()
            },
            cambiar2() {
                this.dialog2 = !this.dialog2
                // this.sendEvent()
            },
            cambiar3(){
                this.dialog2 = !this.dialog2
                this.dialog3 = !this.dialog3
                this.sendEvent()
            },
            sendEvent(){

                axios.post("/save_events.php", {

                    id: this.id,
                    lugar: this.lugar,
                    artista: this.artista

                    }).then(function(response) {

                        // console.log(response)
                        // console.log(response.data)

                    }).catch(error => {

                        // console.log(error)

                    })
            },
            async comprobar(){

                await axios.post("/query_events.php", {

                }).then(function(response) {

                    // console.log(response)
                    console.log(response.data.message)

                    if(response.data.message != 'evento_elegido'){
                        sessionStorage.show = 'mostrar'
                    }else{
                        sessionStorage.show = 'apagar'
                    }

                }).catch(error => {

                    // console.log(error)

                })

                this.show_events = sessionStorage.show
                
            },
            atHome(){
                window.location.replace("/AgregarUnaFoto")
            }
        },
        created(){
            this.comprobar()
        }
}
</script>
<style scoped>

    ::-webkit-scrollbar {
        display: none;
    }
    .fllave{
        background-image: url('../assets/llave.png');
        background-position-x: center;
        background-size: contain;
        min-height: 150px;
    }
    .close_ticket{
        display: flex;
        right: 10px;
    }
    .titulo{
        font-size: 25pt;
        font-weight: bold;
    }
    @media (max-width: 960px){
        .titulo{
            font-size: 16pt;
            font-weight: bold;
        }
        .close_ticket{
            display: flex;
            right: 10px;
        }
    }
</style>